<template>
  <section class="section is-main-section fullHeightSection">
    <div class="columns">
      <div class="column">
        <AssessmentsList v-if="residentId" :residentId="residentId" v-model="assessmentType" />
      </div>
      <div class="column">
        <CarePlanList :residentId="residentId" :assessmentType="assessmentType" />
      </div>
    </div>
  </section>
</template>

<script>
import CarePlanList from "@/components/careplans/CarePlanList";
import AssessmentsList from "@/components/assessments/AssessmentsList.vue";
import ResidentMixin from "@/mixins/residentMixin";

export default {
  mixins: [ResidentMixin],
  components: {
    AssessmentsList,
    CarePlanList
  },
  data() {
    return {
      assessmentType: null // used to pass from AssessmentsList to CarePlanList to scroll to relative section
    };
  }
};
</script>
